var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.element && _vm.element.key
    ? _c(
        "el-form-item",
        {
          staticClass: "created-view",
          class: {
            active: _vm.selectFormData.key === _vm.element.key,
            is_req: _vm.element.options.required,
          },
          attrs: { label: _vm.element.name },
          nativeOn: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.handleSelectForm(_vm.index)
            },
          },
        },
        [
          _vm.element.type === "input"
            ? [
                _c("el-input", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    placeholder: _vm.element.options.placeholder,
                    disabled: _vm.element.options.disabled,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "textarea"
            ? [
                _c("el-input", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    type: "textarea",
                    rows: 5,
                    disabled: _vm.element.options.disabled,
                    placeholder: _vm.element.options.placeholder,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "number"
            ? [
                _c("el-input-number", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    disabled: _vm.element.options.disabled,
                    "controls-position": _vm.element.options.controlsPosition,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "radio"
            ? [
                _c(
                  "el-radio-group",
                  {
                    style: { width: _vm.element.options.width },
                    attrs: { disabled: _vm.element.options.disabled },
                    model: {
                      value: _vm.element.options.defaultValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.element.options, "defaultValue", $$v)
                      },
                      expression: "element.options.defaultValue",
                    },
                  },
                  _vm._l(_vm.element.options.options, function (item, index) {
                    return _c(
                      "el-radio",
                      {
                        key: item.value + index,
                        style: {
                          display: _vm.element.options.inline
                            ? "inline-block"
                            : "block",
                        },
                        attrs: { label: item.value },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.element.options.showLabel
                                ? item.label
                                : item.value
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]
            : _vm._e(),
          _vm.element.type === "checkbox"
            ? [
                _c(
                  "el-checkbox-group",
                  {
                    style: { width: _vm.element.options.width },
                    attrs: { disabled: _vm.element.options.disabled },
                    model: {
                      value: _vm.element.options.defaultValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.element.options, "defaultValue", $$v)
                      },
                      expression: "element.options.defaultValue",
                    },
                  },
                  _vm._l(_vm.element.options.options, function (item, index) {
                    return _c(
                      "el-checkbox",
                      {
                        key: item.value + index,
                        style: {
                          display: _vm.element.options.inline
                            ? "inline-block"
                            : "block",
                        },
                        attrs: { label: item.value },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.element.options.showLabel
                                ? item.label
                                : item.value
                            ) +
                            " "
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ]
            : _vm._e(),
          _vm.element.type === "time"
            ? [
                _c("el-time-picker", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    "is-range": _vm.element.options.isRange,
                    placeholder: _vm.element.options.placeholder,
                    "start-placeholder": _vm.element.options.startPlaceholder,
                    "end-placeholder": _vm.element.options.endPlaceholder,
                    readonly: _vm.element.options.readonly,
                    disabled: _vm.element.options.disabled,
                    editable: _vm.element.options.editable,
                    clearable: _vm.element.options.clearable,
                    arrowControl: _vm.element.options.arrowControl,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "date"
            ? [
                _c("el-date-picker", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    type: _vm.element.options.type,
                    "is-range": _vm.element.options.isRange,
                    placeholder: _vm.element.options.placeholder,
                    "start-placeholder": _vm.element.options.startPlaceholder,
                    "end-placeholder": _vm.element.options.endPlaceholder,
                    readonly: _vm.element.options.readonly,
                    disabled: _vm.element.options.disabled,
                    editable: _vm.element.options.editable,
                    clearable: _vm.element.options.clearable,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "rate"
            ? [
                _c("el-rate", {
                  attrs: {
                    max: _vm.element.options.max,
                    disabled: _vm.element.options.disabled,
                    "allow-half": _vm.element.options.allowHalf,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "color"
            ? [
                _c("el-color-picker", {
                  attrs: {
                    disabled: _vm.element.options.disabled,
                    "show-alpha": _vm.element.options.showAlpha,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "select"
            ? [
                _c(
                  "el-select",
                  {
                    style: { width: _vm.element.options.width },
                    attrs: {
                      disabled: _vm.element.options.disabled,
                      multiple: _vm.element.options.multiple,
                      clearable: _vm.element.options.clearable,
                      placeholder: _vm.element.options.placeholder,
                    },
                    model: {
                      value: _vm.element.options.defaultValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.element.options, "defaultValue", $$v)
                      },
                      expression: "element.options.defaultValue",
                    },
                  },
                  _vm._l(_vm.element.options.options, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: {
                        value: item.value,
                        label: _vm.element.options.showLabel
                          ? item.label
                          : item.value,
                      },
                    })
                  }),
                  1
                ),
              ]
            : _vm._e(),
          _vm.element.type === "switch"
            ? [
                _c("el-switch", {
                  attrs: { disabled: _vm.element.options.disabled },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "slider"
            ? [
                _c("el-slider", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    min: _vm.element.options.min,
                    max: _vm.element.options.max,
                    disabled: _vm.element.options.disabled,
                    step: _vm.element.options.step,
                    "show-input": _vm.element.options.showInput,
                    range: _vm.element.options.range,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "imgupload"
            ? [
                _c("Upload", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    disabled: _vm.element.options.disabled,
                    width: _vm.element.options.size.width,
                    height: _vm.element.options.size.height,
                    token: "xxx",
                    domain: "xxx",
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "cascader"
            ? [
                _c("el-cascader", {
                  style: { width: _vm.element.options.width },
                  attrs: {
                    disabled: _vm.element.options.disabled,
                    clearable: _vm.element.options.clearable,
                    placeholder: _vm.element.options.placeholder,
                    options: _vm.element.options.remoteOptions,
                  },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "editor"
            ? [
                _c("vue-editor", {
                  style: { width: _vm.element.options.width },
                  model: {
                    value: _vm.element.options.defaultValue,
                    callback: function ($$v) {
                      _vm.$set(_vm.element.options, "defaultValue", $$v)
                    },
                    expression: "element.options.defaultValue",
                  },
                }),
              ]
            : _vm._e(),
          _vm.element.type === "blank"
            ? [
                _c(
                  "div",
                  {
                    staticStyle: {
                      height: "50px",
                      color: "#999",
                      background: "#eee",
                      "line-height": "50px",
                      "text-align": "center",
                    },
                  },
                  [_vm._v("自定义区域")]
                ),
              ]
            : _vm._e(),
          _vm.element.type === "text"
            ? [_c("span", [_vm._v(_vm._s(_vm.element.options.defaultValue))])]
            : _vm._e(),
          _vm.selectFormData.key === _vm.element.key
            ? _c("div", { staticClass: "created-view-action" }, [
                _c("i", {
                  staticClass: "el-icon-document-copy",
                  attrs: { title: "复制" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleCloneForm(_vm.index)
                    },
                  },
                }),
                _c("i", {
                  staticClass: "el-icon-delete",
                  attrs: { title: "删除" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleDeleteForm(_vm.index)
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm.selectFormData.key === _vm.element.key
            ? _c("div", { staticClass: "created-view-drag" }, [
                _c("i", {
                  staticClass: "el-icon-sort drag-form",
                  attrs: { title: "拖动" },
                }),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }