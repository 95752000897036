var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          height: "100%",
          padding: "10px",
          "box-sizing": "border-box",
        },
      },
      [_c("script", { attrs: { id: "editor", type: "text/plain" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }