var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-config-container" },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "标签对齐方式" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.data.labelPosition,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "labelPosition", $$v)
                    },
                    expression: "data.labelPosition",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "left" } }, [
                    _vm._v("左对齐"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "right" } }, [
                    _vm._v("右对齐"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "top" } }, [
                    _vm._v("顶对齐"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "表单标签宽度" } },
            [
              _c("el-input-number", {
                attrs: { min: 0, max: 200, size: "small", step: 10 },
                model: {
                  value: _vm.data.labelWidth,
                  callback: function ($$v) {
                    _vm.$set(_vm.data, "labelWidth", $$v)
                  },
                  expression: "data.labelWidth",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "组件大小" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { size: "small" },
                  model: {
                    value: _vm.data.size,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "size", $$v)
                    },
                    expression: "data.size",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: "medium" } }, [
                    _vm._v("medium"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "small" } }, [
                    _vm._v("small"),
                  ]),
                  _c("el-radio-button", { attrs: { label: "mini" } }, [
                    _vm._v("mini"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }