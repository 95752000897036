var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "createPost-container" },
    [
      _c(
        "el-container",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "el-header",
            { staticStyle: { padding: "0", height: "42px" } },
            [
              _c("sticky", { attrs: { className: "sub-navbar " } }, [
                _c(
                  "div",
                  { staticClass: "filter-container" },
                  [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "filter-item",
                        staticStyle: { "margin-left": "10px" },
                        attrs: { size: "mini" },
                        on: { click: _vm.submitForm },
                      },
                      [_vm._v("保存 ")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-main",
            { staticStyle: { padding: "0", height: "calc(100% - 42px)" } },
            [
              _c(
                "el-container",
                { staticStyle: { height: "100%" } },
                [
                  _c(
                    "el-aside",
                    { staticStyle: { background: "#fff" } },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "postForm",
                          staticClass: "form-container",
                          attrs: {
                            model: _vm.postForm,
                            rules: _vm.rules,
                            "label-position": "top",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "createPost-main-container",
                              staticStyle: { margin: "0", height: "100%" },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "name" } },
                                [
                                  _c(
                                    "MDinput",
                                    {
                                      attrs: {
                                        name: "name",
                                        required: "",
                                        maxlength: 100,
                                      },
                                      model: {
                                        value: _vm.postForm.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.postForm, "name", $$v)
                                        },
                                        expression: "postForm.name",
                                      },
                                    },
                                    [_vm._v(" 标题 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "摘要",
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      type: "textarea",
                                      rows: 6,
                                      placeholder: "请输入内容",
                                    },
                                    model: {
                                      value: _vm.postForm.description,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.postForm,
                                          "description",
                                          $$v
                                        )
                                      },
                                      expression: "postForm.description",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.contentShortLength,
                                          expression: "contentShortLength",
                                        },
                                      ],
                                      staticClass: "word-counter",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.contentShortLength) + "字"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "表单类型",
                                    "label-position": "top",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: { placeholder: "" },
                                      model: {
                                        value: _vm.postForm.frmType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.postForm, "frmType", $$v)
                                        },
                                        expression: "postForm.frmType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          label: "可拖拽动态表单",
                                          value: 2,
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "动态表单", value: 0 },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          label: "自定义开发页面",
                                          value: 1,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.postForm.frmType == 1
                                    ? _c(
                                        "el-select",
                                        {
                                          staticStyle: {
                                            "margin-top": "10px",
                                            width: "100%",
                                          },
                                          attrs: {
                                            placeholder: "请选择系统内置的页面",
                                          },
                                          model: {
                                            value: _vm.postForm.webId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.postForm,
                                                "webId",
                                                $$v
                                              )
                                            },
                                            expression: "postForm.webId",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "请假条",
                                              value: "FrmLeaveReq",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-main",
                    { staticStyle: { padding: "0" } },
                    [
                      _vm.postForm.frmType === 0 || _vm.postForm.frmType === 2
                        ? _c(
                            "div",
                            {
                              staticClass: "editor-container",
                              staticStyle: { height: "100%" },
                            },
                            [
                              _vm.postForm.frmType === 2
                                ? _c("FormContainer", {
                                    ref: "contentDataForm",
                                    attrs: { "edit-info": _vm.editInfo },
                                  })
                                : _vm._e(),
                              _vm.postForm.frmType === 0
                                ? [
                                    _c("Ueditor", {
                                      ref: "ue",
                                      attrs: {
                                        content: _vm.postForm.content,
                                        formType: _vm.postForm.frmType,
                                        fileds: _vm.postForm.fields,
                                      },
                                      on: { ready: _vm.ueReady },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.postForm.frmType == 1
                        ? _c(
                            "el-card",
                            { staticClass: "box-card" },
                            [
                              _c(
                                _vm.postForm.webId != "" &&
                                  _vm.postForm.webId + "Add",
                                { tag: "component" }
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }